<template>
    <div class="status">
        <v-card
            v-for="client in apis"
            :key="client.name"
            class="statusCard"
            :class="{ live: client.status == 2, notLive: client.status != 2 }"
        >
            <v-card-title>{{ client.name }}</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>{{ displayStatus(client.status) }}</v-col>
                    <v-col>{{ displayLatency(client.duration) }}</v-col>
                    <v-col class="availabilityPercent">{{ displayPercent(client.name) }}</v-col>
                </v-row>
                <v-row>
                    <ApiDayStatus :site="client.name" :api="api"/>
                </v-row>
                <v-row>
                    <ApiDailyStatus :site="client.name" :api="api"/>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import applicationApi from '@/services/applicationApi'
import auth from '@/auth'
import { HubConnectionBuilder } from '@microsoft/signalr'
import ApiDayStatus from './ApiDayStatus.vue'
import ApiDailyStatus from './ApiDailyStatus.vue'

// const statusConnection = new HubConnectionBuilder().withUrl("https://localhost:5000/apistatus").withAutomaticReconnect().build();
const statusConnection = new HubConnectionBuilder().withUrl("https://api.status.unify.fun/apistatus").withAutomaticReconnect().build();

const api = new applicationApi()
api.useAuth(auth)

let apis = ref([])
let connection = ref(false)
let timer = ref({})
let connectionTime = ref({})
let historicData = ref([])
let historic = ref(false)

statusConnection.on("Display", function (statusList) {
    apis.value = statusList
    connection.value = true
    clearInterval(timer)
    socketConnectionTimer()
})

statusConnection.start().then(function () {
    console.log("Start connection")
    connection.value = true
    socketConnectionTimer()
    test()
}).catch(function (err) {
    return console.error(err.toString())
})

function displayStatus(status) {
    switch (status) {
        case 2:
            return "Healthy"
        default:
            return "Unhealthy"
    }
}

function socketConnectionTimer() {
    let start = Date.now()
    timer = setInterval(function() {
        var delta = Date.now() - start;
        if (delta > 5500) {
            clearInterval(timer)
            connection.value = false
            return;
        }
        let connVal = 1 - (delta / 5000)
        connectionTime.value = connVal
    }, 100);
}

async function test() {
    let start = Date.now()

    while (apis.value.length == 0) {
        await new Promise(r => setTimeout(r, 1000))
    }

    setInterval(function() {
        var delta = Date.now() - start;

        if (delta > (60000 * 5) || !historic.value) {
            historic.value = true
            getHistoricData()
            start = Date.now()
        }
    }, 1000)
}

async function getHistoricData() {
    let apiList = apis.value.map(c => c.name)
    apiList.forEach(app => {
        api.apiStatusRoutes.get(app).then(response => {
            let statuses = response.data.map(d => d.status)
            let upCount = statuses.filter(s => s == 2).length
            let percent = (upCount / statuses.length) * 100

            if (historicData.value.find(h => h.name == app) == undefined) {
                historicData.value.push({ "api": app, "percent": percent })
            } else {
                historicData.value.find(h => h.name == app).percent = percent
            }
        })
    })
}

function displayPercent(app) {
    let historic = historicData.value.find(h => h.api == app)
    if (historic != undefined) {
        return `Availablility: ${Math.round(historic.percent * 100000) / 100000}%`
    }
    return 'Awaiting percentage uptime'
}

function displayLatency(duration) {
    return `Latency: ${duration}`
}

</script>

<style scoped>

.status {
    display: inline-grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
}

.socketStatusIcon {
    width: 20px;
    aspect-ratio: 1;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 2px;
    background-color: rgba(200, 50, 50, 0.6);
}

.socketTrue {
    background-color: rgba(50, 200, 50, 0.6);
}

.apiStatus {
    display: inline-grid;
    margin: 10px 10px;
}

.clientStatus {
    display: inline-grid;
    width: 22vw;
}

.statusCard {
    display: inline-grid;
    margin: 10px 10px;
}

.live {
    background-color: rgba(80, 250, 123, 0.7);
}

.notLive {
    background-color: rgba(255, 85, 85, 0.7);
}

.site {
    position: relative;
    overflow: hidden;
}

.availabilityPercent {
    display: flex;
    justify-content: flex-end;
}

</style>
