<template>
    <div class="hMap">
        <apexcharts width="100%" height="80" type="heatmap" :options="chartOptions" :series="series"></apexcharts>
    </div>
</template>

<script>

import ApexCharts from 'vue-apexcharts'

export default {
    name: "DayStatus",
    components: {
        apexcharts: ApexCharts
    },
    props: ['site', 'api'],
    data: function() {
        return {
            chartOptions: {
                chart: {
                    id: "availability-hmap",
                    type: "heatmap"
                },
                title: {
                    text: "Hourly Availability Last 24hrs",
                    align: 'center',
                    margin: 0,
                    offsetY: 20,
                    style: {
                        fontSize: '10px'
                    }
                },
                plotOptions: {
                    heatmap: {
                        enableShades: false,
                        reverseNegativeShade: true,
                        colorScale: {
                            ranges: [{
                                from: 0,
                                to: 9.999999,
                                color: '#FF0000'
                            }, {
                                from: 10,
                                to: 99.998999,
                                color: '#FFB200'
                            }, {
                                from: 99.999,
                                to: 100,
                                color: '#00A100'
                            }]
                        }
                    }
                },
                xaxis: {
                    categories: [],
                    labels: {
                        show: false
                    }
                },
                yaxis: {
                    labels: {
                        show: false
                    }
                },
                legend: {
                    show: false
                },
                dataLabels: {
                    enabled: false,
                },
                tooltip: {
                    y: {
                        formatter: function(val) {
                            return val + '%'
                        }
                    }
                },
            },
            series: [{
                name: 'Available',
                data: []
            }]
        }
    },
    async mounted() {
        this.api.statusRoutes.get24hrAvailability(this.site).then(response => {

            this.chartOptions = {
                xaxis: {
                    categories: response.data.map(d => d.time)
                }
            }
            this.series = [{
                name: 'Available',
                data: response.data.map(d => d.percent)
            }]
        })
    },
}
</script>

<style>

.hMap {
    width: 100%;
}

</style>
