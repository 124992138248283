<template>
    <main>
        <v-row>
            <v-col>
                <h1>Status</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <ClientStatuses />
            </v-col>
        </v-row>
    </main>
</template>

<script>
import ClientStatuses from '@/components/ClientStatuses.vue'

export default {
    name: "StaTus",

    components: {
        ClientStatuses,
    },

}
</script>

<style>

</style>
