<template>
    <main>
        <v-row>
            <v-col>
                <h1>Api Status</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <ApiStatuses />
            </v-col>
        </v-row>
    </main>
</template>

<script>
import ApiStatuses from '@/components/ApiStatuses.vue'

export default {
    name: "ApiStatus",

    components: {
        ApiStatuses,
    }
}
</script>
