<template>
    <div class="status">
        <v-card
            v-for="client in clients"
            :key="client.site"
            class="statusCard" :class="{ live: client.up == 200, notLive: client.up != 200 }"
        >
            <v-card-title>{{ client.site }}</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>{{ displayStatus(client.up) }}</v-col>
                    <v-col class="availabilityPercent">{{ displayPercent(client.site) }}</v-col>
                </v-row>
                <v-row>
                    <DayStatus :site="client.site" :api="api"/>
                </v-row>
                <v-row>
                    <DailyStatus :site="client.site" :api="api"/>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import applicationApi from '@/services/applicationApi'
import auth from '@/auth'
import { HubConnectionBuilder } from '@microsoft/signalr'
import DayStatus from './DayStatus.vue'
import DailyStatus from './DailyStatus.vue'

// const statusConnection = new HubConnectionBuilder().withUrl("http://localhost:5143/message", {
// // const statusConnection = new HubConnectionBuilder().withUrl("https://api.det.unify.fun/message", {
//     accessTokenFactory: () => {
//         return auth.accessToken
//     }
// }).withAutomaticReconnect().build();
const statusConnection = new HubConnectionBuilder().withUrl("https://api.status.unify.fun/status").withAutomaticReconnect().build();

const api = new applicationApi()
api.useAuth(auth)

let clients = ref([])
let connection = ref(false)
let timer = ref({})
let connectionTime = ref({})
let historicData = ref([])
let historic = ref(false)

statusConnection.on("DisplayStatus", function (statusList) {
    clients.value = statusList
    connection.value = true
    clearInterval(timer)
    socketConnectionTimer()
})

statusConnection.start().then(function () {
    console.log("Start connection")
    connection.value = true
    socketConnectionTimer()
    test()
}).catch(function (err) {
    return console.error(err.toString())
})

function displayStatus(status) {
    switch (status) {
        case 200:
            return "Live"
        case 443:
            return "Name Resolution Error"
        default:
            return "Unavailable"
    }
}

function socketConnectionTimer() {
    let start = Date.now()
    timer = setInterval(function() {
        var delta = Date.now() - start;
        if (delta > 5500) {
            clearInterval(timer)
            connection.value = false
            return;
        }
        let connVal = 1 - (delta / 5000)
        connectionTime.value = connVal
    }, 100);
}

async function test() {
    let start = Date.now()

    while (clients.value.length == 0) {
        await new Promise(r => setTimeout(r, 1000))
    }

    setInterval(function() {
        var delta = Date.now() - start;

        if (delta > (60000 * 5) || !historic.value) {
            historic.value = true
            getPercent()
            start = Date.now()
        }
    }, 1000)
}

async function getPercent() {
    let sites = clients.value.map(c => c.site.substring(8))

    sites.forEach(site => {
        api.statusRoutes.getPercent(site).then(response => {
            let percent = response.data
            if (historicData.value.find(h => h.client == site) == undefined) {
                historicData.value.push({ "client": site, "percent": percent })
            } else {
                historicData.value.find(h => h.client == site).percent = percent
            }
        })
    })
}

function displayPercent(site) {
    let historic = historicData.value.find(h => h.client == site.substring(8))
    if (historic != undefined) {
        return `Availablility: ${Math.round(historic.percent * 100000) / 100000}%`
    }
    return 'Awaiting percentage uptime'
}

</script>

<style scoped>

.status {
    display: inline-grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
}

.socketStatusIcon {
    width: 20px;
    aspect-ratio: 1;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 2px;
    background-color: rgba(200, 50, 50, 0.6);
}

.socketTrue {
    background-color: rgba(50, 200, 50, 0.6);
}

.apiStatus {
    display: inline-grid;
    margin: 10px 10px;
}

.clientStatus {
    display: inline-grid;
    width: 22vw;
}

.statusCard {
    display: inline-grid;
    margin: 10px 10px;
}

.live {
    background-color: rgba(80, 250, 123, 0.7);
}

.notLive {
    background-color: rgba(255, 85, 85, 0.7);
}

.site {
    position: relative;
    overflow: hidden;
}

.availabilityPercent {
    display: flex;
    justify-content: flex-end;
}

</style>
