// import { apiServer } from '@/config'
import axios from 'axios'
import applicationRoutes from './applicationRoutes'
import statusRoutes from './statusRoutes'
import apiStatusRoutes from './apiStatusRoutes'

export default class apiService {
    constructor() {
        this.apiService = axios.create()
        // this.apiService.defaults.baseURL = (apiServer.ssl ? 'https' : 'http') + '://' + apiServer.host + ( apiServer.rootPrefix == '' ? ((apiServer.ssl && apiServer.port == '443') || (!apiServer.ssl && apiServer.port == '80') || !apiServer.port ? '' : ':' + apiServer.port) : apiServer.rootPrefix ) + '/'
        // this.apiService.defaults.baseURL = "https://localhost:5000"
        this.apiService.defaults.baseURL = "https://api.status.unify.fun"

        this.applicationRoutes = new applicationRoutes(this.apiService)
        this.statusRoutes = new statusRoutes(this.apiService)
        this.apiStatusRoutes = new apiStatusRoutes(this.apiService)
    }

    useAuth(auth) {
        this.apiService.interceptors.request.use((config) => {
            config.headers.Authorization = `Bearer ${auth.accessToken}`
            return config
        })
    }
}