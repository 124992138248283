import Vue from 'vue'
import VueRouter from 'vue-router'

import Build from '@/views/build'
import Home from '@/views/build/Home'
import Status from '@/views/build/Status'
import ApiStatus from '@/views/build/ApiStatus'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            name: 'Home',
            path: '/',
            component: Build,
            meta: { requiresAuth: true },
            children: [
                { name: 'home', path: '', component: Home},
                { name: 'Status', path: '/client', component: Status },
                { name: 'ApiStatus', path: '/api', component: ApiStatus }
            ]
        },
    ],
})

export default router
