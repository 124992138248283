export default class statusRoutes {
    constructor(apiService) {
        this.apiService = apiService
    }

    getAll() {
        return this.apiService.get(`v1/status`)
    }

    get(appUrl) {
        return this.apiService.post(`v1/status`, appUrl, {headers: {'accept': 'application/json', 'Content-Type': 'application/json'} })
    }

    getPercent(app) {
        return this.apiService.post(`v1/status/uptime/percent`, app, {headers: {'accept': 'application/json', 'Content-Type': 'application/json'} })
    }

    get24hrAvailability(app) {
        return this.apiService.post(`v1/status/availability/24hr`, app, {headers: {'accept': 'application/json', 'Content-Type': 'application/json'} })
    }

    getDailyAvailability(app) {
        return this.apiService.post(`v1/status/availability/daily`, app, {headers: {'accept': 'application/json', 'Content-Type': 'application/json'} })
    }
}
