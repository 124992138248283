export default class apiStatusRoutes {
    constructor(apiService) {
        this.apiService = apiService
    }

    getAll() {
        return this.apiService.get(`v1/apistatus`)
    }

    get(api) {
        return this.apiService.get(`v1/apistatus/${api}`)
    }

    get24hrAvailability(app) {
        return this.apiService.get(`v1/apistatus/availability/${app}/24hr`)
    }

    getDailyAvailability(app) {
        return this.apiService.get(`v1/apistatus/availability/${app}/daily`)
    }
}
