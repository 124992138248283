import { render, staticRenderFns } from "./ApiStatuses.vue?vue&type=template&id=27d56d08&scoped=true"
import script from "./ApiStatuses.vue?vue&type=script&setup=true&lang=js"
export * from "./ApiStatuses.vue?vue&type=script&setup=true&lang=js"
import style0 from "./ApiStatuses.vue?vue&type=style&index=0&id=27d56d08&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27d56d08",
  null
  
)

export default component.exports